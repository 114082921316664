var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invoiceContent" },
    [
      _c("div", { staticClass: "label-container" }, [
        _c("div", { staticClass: "label-item" }, [
          _c("div", { staticClass: "label-mark" }, [_vm._v("主单号")]),
          _c("div", { staticClass: "label-info" }, [
            _vm._v(_vm._s(_vm.row.mblNo)),
          ]),
        ]),
        _vm.row.containerNumber
          ? _c("div", { staticClass: "label-item" }, [
              _c("div", { staticClass: "label-mark" }, [_vm._v("柜号")]),
              _c("div", { staticClass: "label-info" }, [
                _vm._v(_vm._s(_vm.row.containerNumber)),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "label-item" }, [
          _c("div", { staticClass: "label-mark" }, [_vm._v("提单件数")]),
          _c("div", { staticClass: "label-info" }, [
            _vm._v(_vm._s(_vm.row.qty)),
          ]),
        ]),
        _c("div", { staticClass: "label-item" }, [
          _c("div", { staticClass: "label-mark" }, [_vm._v("提单重量")]),
          _c("div", { staticClass: "label-info" }, [
            _vm._v(_vm._s(_vm.row.weightKgs)),
          ]),
        ]),
        _c("div", { staticClass: "label-item" }, [
          _c("div", { staticClass: "label-mark" }, [_vm._v("总税金")]),
          _c(
            "div",
            {
              staticClass: "label-info",
              staticStyle: { color: "#2395f3", cursor: "pointer" },
              attrs: { title: "下载普通发票" },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.mdlType ? _vm.sums[18] : _vm.sums[19]) + " "
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.invoiceLists.data,
                border: "",
                "show-summary": "",
                width: "100%",
                height: _vm.height,
                "header-cell-style": _vm.headerStyle,
                "cell-style": _vm.cellStyle,
                "summary-method": _vm.getSummaries,
              },
              on: {
                "cell-dblclick": _vm.dblclick,
                "header-click": _vm.headerClick,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "54",
                  fixed: "left",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "54",
                  align: "center",
                  label: "序号",
                },
              }),
              _vm._l(_vm.invoiceLists.fileds, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.filed,
                    label: item.text,
                    width: item.width,
                    resizable: false,
                    align: "center",
                    "header-align": "center",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isEdit.length > 0 &&
                            scope.row.isEdit.indexOf(item.filed) > -1
                              ? _c("el-input", {
                                  ref: "input",
                                  refInFor: true,
                                  attrs: {
                                    focus: "",
                                    placeholder: "请输入内容",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.blur(scope.row)
                                    },
                                    blur: function ($event) {
                                      return _vm.blur(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row[item.filed],
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, item.filed, $$v)
                                    },
                                    expression: "scope.row[item.filed]",
                                  },
                                })
                              : _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                      "align-items": "center",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.cellClick(
                                          scope.row,
                                          scope.column
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row[item.filed]))]
                                ),
                          ]
                        },
                      },
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex_c_c" },
                              [
                                _vm._v(" " + _vm._s(scope.column.label) + " "),
                                _vm.whiteList.indexOf(item.filed) > -1 &&
                                _vm.headFixed.indexOf(item.filed) === -1
                                  ? _c("svg-icon", {
                                      attrs: {
                                        className: "icon",
                                        iconClass: "edit",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.whiteList.indexOf(item.filed) > -1 &&
                                _vm.headFixed.indexOf(item.filed) > -1
                                  ? _c("svg-icon", {
                                      attrs: {
                                        className: "icon",
                                        iconClass: "not-edit",
                                      },
                                    })
                                  : _vm._e(),
                                item.showTip
                                  ? [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            tabindex: 2000,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            attrs: { slot: "content" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                item.headerCentent
                                              ),
                                            },
                                            slot: "content",
                                          }),
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/table/question.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "tableFooter" }, [
          _c("span", { staticClass: "selection" }, [
            _vm._v(" 已选择 "),
            _c("span", { staticClass: "selection_num" }, [
              _vm._v(_vm._s(_vm.mutiSelection.length)),
            ]),
            _vm._v(" 项"),
          ]),
          _c(
            "div",
            {
              staticClass: "btn",
              staticStyle: { "margin-left": "10px" },
              on: { click: _vm.merge },
            },
            [
              _c("svg-icon", {
                attrs: { className: "icon", iconClass: "merge" },
              }),
              _vm._v("合并 "),
            ],
            1
          ),
          _c("div", { staticClass: "btn", on: { click: _vm.deleteRow } }, [
            _c("i", { staticClass: "el-icon-delete" }),
            _vm._v("删除 "),
          ]),
        ]),
        _c("div", { staticClass: "btn1", on: { click: _vm.cancel } }, [
          _vm._v("取消"),
        ]),
        _c("div", { staticClass: "btn1", on: { click: _vm.save } }, [
          _vm._v("暂存"),
        ]),
        _vm.$store.state.invoiceLoop?.caches.length > 0
          ? _c("div", { staticClass: "btn2", on: { click: _vm.prev } }, [
              _vm._v("上一步"),
            ])
          : _vm._e(),
        _c("div", { staticClass: "btn2", on: { click: _vm.ok } }, [
          _vm._v("接受"),
        ]),
        _c("div", { staticClass: "btn2", on: { click: _vm.refuse } }, [
          _vm._v("拒绝"),
        ]),
      ]),
      _c("ProductDialog", {
        attrs: { dialogVisible: _vm.dialogVisible1 },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
          ok: _vm.ok1,
        },
      }),
      _c("TaxDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2 },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
          ok: _vm.ok2,
        },
      }),
      _c("MergeInvoiceDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible4,
          productRow: _vm.mutiSelection,
        },
        on: {
          cancer: function ($event) {
            _vm.dialogVisible4 = false
          },
          ok: _vm.ok3,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }