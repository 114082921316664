<template>
  <el-dialog
    title="合并"
    :visible.sync="dialogVisible"
    width="1140px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancer"
  >
    <div class="container">
      <BaseTable
        :height="360"
        :tableData="tableData"
        :loading="loading"
        :isPager="false"
        :showTooltip="false"
        :border="true"
        @handlerRadio="handlerRadio"
      >
        <template slot="nameEN" slot-scope="row">
          <template v-if="row.scope.nameEN.length >= 33">
            <el-popover trigger="hover" placement="top">
              <p>{{ row.scope.nameEN }}</p>
              <div slot="reference">
                <span class="ellipsis">{{
                  row.scope.nameEN
                }}</span>
              </div>
            </el-popover>
          </template>
          <template v-else>
            <span class="ellipsis">{{ row.scope.nameEN }}</span>
          </template>
        </template>
        <template slot="nameCH" slot-scope="row">
          <template v-if="row.scope.nameCH.length >= 10">
            <el-popover trigger="hover" placement="top">
              <p>{{ row.scope.nameCH }}</p>
              <div slot="reference">
                <span class="ellipsis">{{
                  row.scope.nameCH
                }}</span>
              </div>
            </el-popover>
          </template>
          <template v-else>
            <span class="ellipsis">{{ row.scope.nameCH }}</span>
          </template>
        </template>
        <template slot="material" slot-scope="row">
          <el-popover trigger="hover" placement="top">
            <p>{{ row.scope.material }}</p>
            <div slot="reference">
              <span class="ellipsis">{{
                row.scope.material
              }}</span>
            </div>
          </el-popover>
        </template>
        <template slot="used" slot-scope="row">
          <el-popover trigger="hover" placement="top">
            <p>{{ row.scope.used }}</p>
            <div slot="reference">
              <span class="ellipsis">{{ row.scope.used }}</span>
            </div>
          </el-popover>
        </template>
      </BaseTable>
    </div>
    <span slot="footer" class="footers">
      <div class="cancer" @click="cancer">取 消</div>
      <div class="ok" @click="ok('ruleForm')">合并</div>
    </span>
  </el-dialog>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable.vue')
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    productRow: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'nameEN',
            label: '​Product name',
            width: '150',
            formatter: (row) => {
              return row.nameEN
            },
            isCustom: true
          },
          {
            filed: 'nameCH',
            label: '中文品名',
            width: '100',
            formatter: (row) => {
              return row.nameCH
            },
            isCustom: true
          },
          {
            filed: 'hsCode',
            label: '​HS CODE',
            width: '108',
            formatter: (row) => {
              return row.hsCode
            }
          },
          {
            filed: 'material',
            label: '​Material',
            width: '',
            formatter: (row) => {
              return row.material
            },
            isCustom: true
          },
          {
            filed: 'used',
            label: '​Used for',
            width: '',
            formatter: (row) => {
              return row.used
            },
            isCustom: true
          },
          {
            filed: 'packages',
            label: '​Ctns',
            width: ''
          },
          {
            filed: 'qty',
            label: '​Qty',
            width: '',
            formatter: (row) => {
              return row.qty
            }
          },
          {
            filed: 'unitPrice',
            label: '​Unit Price',
            width: '',
            formatter: (row) => {
              return row.unitPrice
            }
          },
          {
            filed: 'totalPrice',
            label: '​Toral Price',
            width: '',
            formatter: (row) => {
              return +(
                row.qty * row.unitPrice
              ).toFixed(2)
            }
          },
          {
            filed: 'netWeight',
            label: 'Net.W',
            width: '',
            formatter: (row) => {
              return row.netWeight
            }
          },
          {
            filed: 'grossWeight',
            label: 'Gro.W',
            width: '',
            formatter: (row) => {
              return row.grossWeight
            }
          }
        ],
        columns: this.productRow,
        total: this.productRow.length,
        showRadio: true,
        cellStyle({ columnIndex }) {
          if (columnIndex === 0) {
            return {
              background: '#f8fbff'
            }
          }
        }
      },
      loading: false,
      selectRow: null
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        this.tableData.columns = this.productRow
      }
    }
  },
  methods: {
    cancer() {
      this.$emit('cancer')
    },
    ok() {
      if (!this.selectRow) {
        this.$message.warning('请选择合并后的产品条目')
        return false
      }
      let qty = 0
      let packages = 0
      let netWeight = 0
      let grossWeight = 0
      this.productRow.forEach((item) => {
        qty += +item.qty
        packages += +item.packages
        netWeight += +item.netWeight
        grossWeight += +item.grossWeight
      })
      const selectRow = {
        ...this.selectRow,
        qty: qty,
        netWeight: netWeight,
        grossWeight: grossWeight,
        packages
      }
      this.$emit('ok', selectRow)
    },
    handlerRadio(row) {
      this.selectRow = row
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-dialog__body) {
  padding: 20px 20px 40px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
