var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "合并",
        visible: _vm.dialogVisible,
        width: "1140px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancer,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("BaseTable", {
            attrs: {
              height: 360,
              tableData: _vm.tableData,
              loading: _vm.loading,
              isPager: false,
              showTooltip: false,
              border: true,
            },
            on: { handlerRadio: _vm.handlerRadio },
            scopedSlots: _vm._u([
              {
                key: "nameEN",
                fn: function (row) {
                  return [
                    row.scope.nameEN.length >= 33
                      ? [
                          _c(
                            "el-popover",
                            { attrs: { trigger: "hover", placement: "top" } },
                            [
                              _c("p", [_vm._v(_vm._s(row.scope.nameEN))]),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("span", { staticClass: "ellipsis" }, [
                                    _vm._v(_vm._s(row.scope.nameEN)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c("span", { staticClass: "ellipsis" }, [
                            _vm._v(_vm._s(row.scope.nameEN)),
                          ]),
                        ],
                  ]
                },
              },
              {
                key: "nameCH",
                fn: function (row) {
                  return [
                    row.scope.nameCH.length >= 10
                      ? [
                          _c(
                            "el-popover",
                            { attrs: { trigger: "hover", placement: "top" } },
                            [
                              _c("p", [_vm._v(_vm._s(row.scope.nameCH))]),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("span", { staticClass: "ellipsis" }, [
                                    _vm._v(_vm._s(row.scope.nameCH)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c("span", { staticClass: "ellipsis" }, [
                            _vm._v(_vm._s(row.scope.nameCH)),
                          ]),
                        ],
                  ]
                },
              },
              {
                key: "material",
                fn: function (row) {
                  return [
                    _c(
                      "el-popover",
                      { attrs: { trigger: "hover", placement: "top" } },
                      [
                        _c("p", [_vm._v(_vm._s(row.scope.material))]),
                        _c(
                          "div",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _c("span", { staticClass: "ellipsis" }, [
                              _vm._v(_vm._s(row.scope.material)),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "used",
                fn: function (row) {
                  return [
                    _c(
                      "el-popover",
                      { attrs: { trigger: "hover", placement: "top" } },
                      [
                        _c("p", [_vm._v(_vm._s(row.scope.used))]),
                        _c(
                          "div",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _c("span", { staticClass: "ellipsis" }, [
                              _vm._v(_vm._s(row.scope.used)),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "cancer", on: { click: _vm.cancer } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "div",
            {
              staticClass: "ok",
              on: {
                click: function ($event) {
                  return _vm.ok("ruleForm")
                },
              },
            },
            [_vm._v("合并")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }