<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="1008px"
    title="产品库"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    append-to-body
  >
    <div class="opt">
      <div class="add" @click="add">
        <img src="@/assets/images/add.png" alt="" /> 新增
      </div>
      <BaseFilter :isActive="isActive" :width="438" @sendFilter="sendFilter" @reset="reset">
        <div class="filter">
          <el-form ref="form" :model="filterList" label-width="40px">
            <el-form-item label="税号">
              <el-input
                v-model="filterList.hsCode"
                placeholder="请输入税号"
                style="width: 320px;"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </BaseFilter>
    </div>
    <div class="data">
      <BaseTable
        :height="328"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        :isPager="true"
        :border="true"
        @handlerRadio="handlerRadio"
        @handlePageChange="handlePageChange"
        @dblclick="dblclick"
      >
      </BaseTable>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok('form')">确定</div>
    </span>
    <ProductAddDialog :dialogVisible="dialogVisible2" @cancel="cancel2" @ok="ok2">
    </ProductAddDialog>
  </el-dialog>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable')
const ProductAddDialog = () => import('./ProductAddDialog')
const BaseFilter = () => import('@/components/filter/BaseFilter')
import { getTaxProductPage } from '@/api/basic'
export default {
  components: { BaseTable, ProductAddDialog, BaseFilter },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'nameCH',
            label: '中文品名',
            width: '280',
            resizable: true
          },
          {
            filed: 'nameEN',
            label: '英文品名',
            width: '300',
            resizable: true
          },
          {
            filed: 'hsCode',
            label: '美国税号',
            width: '120',
            resizable: true
          },
          {
            filed: 'baseRate',
            label: '基础税率',
            width: '100',
            resizable: true
          },
          {
            filed: 'addRate',
            label: '加征税率',
            width: '100',
            resizable: true
          },
          {
            filed: 'batchNo',
            label: '批次号',
            width: '120',
            resizable: true
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '140',
            resizable: true
          },
          {
            filed: 'remark',
            label: '备注',
            width: '',
            resizable: true
          }
        ],
        columns: [],
        total: 0,
        showRadio: true
      },
      loading: false,
      filterList: {
        hsCode: ''
      },
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      dialogVisible2: false,
      isActive: false,
      tableRadio: {},
      selectRow: null
    }
  },
  created() {
    this.getList(this.pager)
  },
  methods: {
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            hsCode: this.filterList.hsCode
              ? [this.filterList.hsCode]
              : undefined
          }
        }
        const res = await getTaxProductPage(params)
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data.list.map((item) => {
            return {
              ...item,
              tableRadio: false
            }
          })
          this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handlePageChange(pager) {
      this.pager = pager
      this.getList(pager)
    },
    add() {
      this.dialogVisible2 = true
    },
    cancel() {
      this.$emit('cancel')
    },
    handlerRadio(row) {
      this.selectRow = row
    },
    dblclick(row) {
      this.$emit('ok', row)
    },
    ok() {
      if (!this.selectRow) {
        this.$message.warning('请选择一条数据')
      }
      this.$emit('ok', this.selectRow)
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    ok2() {
      this.cancel2()
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = params
      this.getList(params)
    },
    sendFilter() {
      if (this.filterList.hsCode !== '') {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = params
      this.getList(params)
    },
    reset() {
      this.filterList = {
        hsCode: ''
      }
      this.isActive = false
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.el-dialog__body) {
  padding: 30px 14px;
}
.opt {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.add {
  width: 84px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #35ba83;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer;
  line-height: 24px;
}
.data {
  border-radius: 4px;
  overflow: hidden;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
