<template>
  <div class="invoiceContent">
    <div class="label-container">
      <div class="label-item">
        <div class="label-mark">主单号</div>
        <div class="label-info">{{ row.mblNo }}</div>
      </div>
      <div v-if="row.containerNumber" class="label-item">
        <div class="label-mark">柜号</div>
        <div class="label-info">{{ row.containerNumber }}</div>
      </div>
      <div class="label-item">
        <div class="label-mark">提单件数</div>
        <div class="label-info">{{ row.qty }}</div>
      </div>
      <div class="label-item">
        <div class="label-mark">提单重量</div>
        <div class="label-info">{{ row.weightKgs }}</div>
      </div>
      <div class="label-item">
        <div class="label-mark">总税金</div>
        <div
          class="label-info"
          style="color: #2395f3; cursor: pointer"
          title="下载普通发票"
        >
          {{ mdlType ? sums[18] : sums[19] }}
        </div>
      </div>
    </div>
    <div>
      <el-table
        :data="invoiceLists.data"
        border
        show-summary
        width="100%"
        :height="height"
        :header-cell-style="headerStyle"
        :cell-style="cellStyle"
        :summary-method="getSummaries"
        @cell-dblclick="dblclick"
        @header-click="headerClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="54"
          fixed="left"
          align="center"
        >
        </el-table-column>
        <el-table-column type="index" width="54" align="center" label="序号">
        </el-table-column>
        <template>
          <el-table-column
            v-for="(item, index) in invoiceLists.fileds"
            :key="index"
            :prop="item.filed"
            :label="item.text"
            :width="item.width"
            :resizable="false"
            align="center"
            header-align="center"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-input
                v-if="
                  scope.row.isEdit.length > 0 &&
                    scope.row.isEdit.indexOf(item.filed) > -1
                "
                ref="input"
                v-model="scope.row[item.filed]"
                focus
                placeholder="请输入内容"
                @change="blur(scope.row)"
                @blur="blur(scope.row)"
              ></el-input>
              <p
                v-else
                style="display: flex;justify-content: center;align-items: center;"
                @click.prevent.stop="cellClick(scope.row, scope.column)"
              >{{ scope.row[item.filed] }}</p>
            </template>
            <template slot="header" slot-scope="scope">
              <div class="flex_c_c">
                {{ scope.column.label }}
                <svg-icon v-if="whiteList.indexOf(item.filed) > -1 && headFixed.indexOf(item.filed) === -1" className="icon" iconClass="edit" />
                <svg-icon v-if="whiteList.indexOf(item.filed) > -1 && headFixed.indexOf(item.filed) > -1" className="icon" iconClass="not-edit" />
                <template v-if="item.showTip">
                  <el-tooltip effect="dark" :tabindex="2000" placement="top">
                    <div slot="content" v-html="item.headerCentent"></div>
                    <img src="@/assets/images/table/question.png" alt="" />
                  </el-tooltip>
                </template>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="footer">
      <div class="tableFooter">
        <span class="selection">
          已选择
          <span class="selection_num">{{ mutiSelection.length }}</span> 项</span
          >
        <div class="btn" style="margin-left: 10px" @click="merge">
          <svg-icon className="icon" iconClass="merge" />合并
        </div>
        <div class="btn" @click="deleteRow">
          <i class="el-icon-delete" />删除
        </div>
      </div>
      <div class="btn1" @click="cancel">取消</div>
      <div class="btn1" @click="save">暂存</div>
      <div v-if="$store.state.invoiceLoop?.caches.length > 0" class="btn2" @click="prev">上一步</div>
      <div class="btn2" @click="ok">接受</div>
      <div class="btn2" @click="refuse">拒绝</div>
    </div>
    <ProductDialog :dialogVisible="dialogVisible1" @cancel="dialogVisible1 = false" @ok="ok1"></ProductDialog>
    <TaxDialog :dialogVisible="dialogVisible2" @cancel="dialogVisible2 = false" @ok="ok2"></TaxDialog>
    <MergeInvoiceDialog
      :dialogVisible="dialogVisible4"
      :productRow="mutiSelection"
      @cancer="dialogVisible4 = false"
      @ok="ok3"
    >
    </MergeInvoiceDialog>
  </div>
</template>
<script>
import ProductDialog from '../dialog/ProductDialog.vue'
import TaxDialog from '../dialog/TaxDialog.vue'
import MergeInvoiceDialog from '../dialog/MergeInvoiceDialog.vue'
import { singleClick } from '@/utils/common'
import { forecastRefuse } from '@/api/bill'
import _ from 'lodash'
export default {
  components: { ProductDialog, TaxDialog, MergeInvoiceDialog },
  props: {
    editData: {
      type: Array,
      default: function() {
        return [
        ]
      }
    },
    row: {
      type: Object,
      default: function() {
        return {
        }
      }
    },
    mdlType: {
      type: [String, Number],
      default: 'S'
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      rowdata: '',
      invoice: {
        fileds: [
          {
            filed: 'nameEN',
            text: 'Product name',
            fixed: 'left',
            width: '200px'
          },
          {
            filed: 'nameCH',
            text: '中文品名',
            fixed: 'left',
            width: '100px'
          },
          {
            filed: 'hsCode',
            text: 'HS CODE',
            fixed: 'left',
            width: '120px'
          },
          {
            filed: 'material',
            text: 'Material',
            width: '200px'
          },
          {
            filed: 'usedFor',
            text: 'Used for',
            width: '200px'
          },
          {
            filed: 'packages',
            text: 'Ctns',
            width: '85px'
          },
          {
            filed: 'qty',
            text: 'Qty',
            width: '68px'
          },
          {
            filed: 'unitPrice',
            text: 'Unit price',
            width: '90px'
          },
          {
            filed: 'totalPrice',
            text: 'Total price',
            width: '100px'
          },
          {
            filed: 'netWeight',
            text: 'Net.W',
            width: '108px'
          },
          {
            filed: 'grossWeight',
            text: 'Gro.W',
            width: '108px'
          },
          {
            filed: 'unitNetWeight',
            text: '单个净重'
          },
          {
            filed: 'unitGrossWeight',
            text: '单箱毛重'
          },
          {
            filed: 'dutyAmount',
            text: 'Duty'
          },
          {
            filed: 'mpfAmount',
            text: 'MPF',
            width: '60px'
          },
          {
            filed: 'hmfAmount',
            text: 'HMF',
            width: '60px'
          },
          {
            filed: 'mark',
            text: 'mark',
            width: '200'
          },
          {
            filed: 'totalAmount',
            fixed: 'right',
            text: '总税金'
          },
          {
            filed: 'unitGrossAmount',
            fixed: 'right',
            text: '单kg税金'
          }
        ],
        data: this.editData
      },
      selectRow: '',
      mutiSelection: [],
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      whiteList: ['nameEN', 'nameCH', 'hsCode', 'material', 'used'],
      headFixed: [], // 锁定列
      sums: [] // 当前合计行
    }
  },
  computed: {
    invoiceLists: function() {
      const { data } = this.invoice
      console.log(data)
      data.forEach((item) => {
        item.totalPrice = +(item.qty * item.unitPrice).toFixed(2)
        item.unitNetWeight = parseFloat(item.qty, 10)
          ? +(item.netWeight / item.qty).toFixed(2)
          : 0 // 单行净重除以个数
        item.unitGrossWeight = parseFloat(item.packages, 10)
          ? +(item.grossWeight / item.packages).toFixed(2)
          : 0 // 单行总毛重除以箱数
        item.dutyAmount = +(
          (item.totalPrice * (item.addRate || 0 + item.baseRate || 0)) /
          100
        ).toFixed(2) // item.Tprice * rate // 单行总金额与编码对应的税率计算
        item.mpfAmount = +(item.totalPrice * 0.003464).toFixed(2) // 单行总金额*0.3464%
        if (this.mdlType === 0) {
          item.hmfAmount = +(item.totalPrice * 0.00125).toFixed(2) // 单行总金额*0.125%
          item.totalAmount = +(
            parseFloat(+item.dutyAmount, 10) +
            parseFloat(+item.mpfAmount, 10) +
            parseFloat(+item.hmfAmount, 10)
          ).toFixed(2) // DUTY+MPF+HMF
        } else {
          item.totalAmount = +(
            parseFloat(+item.dutyAmount, 10) + parseFloat(+item.mpfAmount, 10)
          ).toFixed(2) // DUTY+MPF
        }
        item.unitGrossAmount = +parseFloat(item.netWeight, 10)
          ? (parseFloat(+item.totalAmount, 10) / +item.netWeight).toFixed(2)
          : 0
        item.isEdit = item.isEdit || []
        item.updateColumnNameList = item.isEupdateColumnNameListdit || []
      })
      return this.invoice
    }
  },
  watch: {
    mdlType: {
      handler: function(val) {
        if (val) {
          this.invoice.fileds.splice(16, 1)
        }
      },
      immediate: true
    }
  },
  created() {
    // const invidata = JSON.parse(
    //   sessionStorage.getItem(`invoiceSave-${this.row.id}`)
    // )
    // this.invidata = invidata
    // if (invidata && this.row.id === invidata.invoiceid) {
    //   this.invoice.data = this.invidata.invoicedata
    // }
  },
  methods: {
    save() {
      for (const [index, items] of this.invoiceLists.data.entries()) {
        for (const keys in items) {
          const obj = {
            'packages': [+items['packages'], 'Ctns'],
            'qty': [+items['qty'], 'Qty'],
            'unitPrice': [+items['unitPrice'], 'Unit Price'],
            'totalPrice': [+items['totalPrice'], 'Total Price'],
            'netWeight': [+items['netWeight'], 'Net.W'],
            'grossWeight': [+items['grossWeight'], 'Gro.W']
          }
          if (obj[keys] && obj[keys][0] === 0) {
            const [num, name] = obj[keys]
            this.$message.error(`第${index + 1}行，${name}值不能为${num}`)
            return
          }
          if (obj[keys] && obj[keys][1] === 'Ctns' && Math.floor(obj[keys][0]) !== obj[keys][0]) {
            this.$message.error(`第${index + 1}行，${obj[keys][1]}值需为整数`)
            return
          }
        }
      }
      this.$emit('save', { items: this.invoiceLists.data })
    },
    cancel() {
      this.$store.commit('set_empty_item')
      this.$emit('cancel')
    },
    ok() {
      let totalPrice = 0
      this.invoiceLists.data.forEach((item) => {
        totalPrice += parseFloat(item.totalPrice, 10)
      })
      console.log(this.row)
      if (this.row.isSensitiveImporter) {
        let str = this.row.importerName + '进口商，该进口商为敏感进口商，'
        if (totalPrice > 100000) {
          str += '且总金额超过10万美金'
        }
        str += '请与客户核实后通过审核!'
        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          iconClass: 'warning',
          showClose: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          center: true
        })
          .then(() => {
            this.$emit('ok', { items: this.invoiceLists.data })
            // sessionStorage.removeItem(`invoiceSave-${this.row.id}`)
          })
          .catch(() => {})
      } else if (totalPrice > 100000) {
        this.$confirm('总金额超过10万美金,请与客户核实后通过审核!', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          iconClass: 'warning',
          showClose: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          center: true
        })
          .then(() => {
            this.$emit('ok', { items: this.invoiceLists.data })
            // sessionStorage.removeItem(`invoiceSave-${this.row.id}`)
          })
          .catch(() => {})
      } else {
        this.$emit('ok', { items: this.invoiceLists.data })
        // sessionStorage.removeItem(`invoiceSave-${this.row.id}`)
      }
    },
    ok1(item) {
      this.dialogVisible1 = false
      this.dialogVisible3 = false
      this.$store.commit('set_loop_item', _.cloneDeep(this.invoice.data))
      const row = this.invoice.data.filter((list) => {
        return list === this.selectRow
      })[0]
      row.nameEN = item.nameEN
      row.nameCH = item.nameCH
      row.hsCode = item.hsCode
      row.material = item.material
      row.usedFor = item.used
      const change = ['nameEN', 'nameCH', 'hsCode']
      if (
        row.updateColumnNameList &&
        row.updateColumnNameList.indexOf('hsCode') === -1
      ) {
        row.updateColumnNameList.push(...change)
      } else {
        row.updateColumnNameList.push(...['nameEN', 'nameCH'])
      }
      row.addRate = item.addRate
      row.baseRate = item.baseRate
      row.isExists = true
      console.log(row)
    },
    ok2(item) {
      this.dialogVisible2 = false
      this.$store.commit('set_loop_item', _.cloneDeep(this.invoice.data))
      const row = this.invoice.data.filter((list) => {
        return list === this.selectRow
      })[0]
      row.hsCode = item.hsCode
      if (
        row.updateColumnNameList &&
        row.updateColumnNameList.indexOf('hsCode') === -1
      ) {
        row.updateColumnNameList.push('hsCode')
      }
      row.addRate = item.addRate
      row.baseRate = item.baseRate
      row.isExists = true
      console.log(row)
    },
    ok3(row) {
      const dataIndex = this.invoiceLists.data.findIndex(
        (a) => a === this.mutiSelection[0]
      )
      const arr = []
      this.$store.commit('set_loop_item', this.invoice.data)
      this.invoice.data.forEach((a, index) => {
        if (index !== dataIndex) {
          if (this.mutiSelection.indexOf(a) < 0) {
            arr.push(a)
          }
        } else {
          arr.push(row)
        }
        return this.mutiSelection.indexOf(a) < 0
      })
      this.dialogVisible4 = false
      this.invoice.data = arr
    },
    headerClick(column) {
      if (this.whiteList.indexOf(column.property) > -1) {
        const index = this.headFixed.findIndex(a => a === column.property)
        if (index > -1) {
          this.headFixed.splice(index, 1)
        } else {
          this.headFixed.push(column.property)
        }
      }
      console.log('column', this.headFixed, column)
    },
    headerStyle({ columnIndex }) {
      if (
        columnIndex === 7 ||
        columnIndex === 10 ||
        (columnIndex > 12 && columnIndex < 21)
      ) {
        return {
          background: '#D6F3E6',
          color: '#333333',
          fontWeight: '400'
        }
      } else {
        return {
          background: '#F8FBFF',
          fontWeight: '400',
          color: '#333333'
        }
      }
    },
    cellStyle({ column, columnIndex }) {
      if (this.headFixed.indexOf(column.property) > -1) {
        if (columnIndex === 4) {
          return {
            background: '#F7F7F7',
            cursor: 'not-allowed',
            color: '#FE453D !important'
          }
        } else {
          return {
            background: '#F7F7F7',
            cursor: 'not-allowed'
          }
        }
      }
      if (columnIndex === 4) {
        return {
          color: '#FE453D !important'
        }
      }
      if (
        columnIndex === 7 ||
        columnIndex === 10 ||
        (columnIndex > 12 && columnIndex < 21)
      ) {
        if (columnIndex === 15 || columnIndex === 18) {
          return {
            background: '#F4FCF9',
            color: '#FE453D'
          }
        }
        return {
          background: '#F4FCF9'
        }
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        if (
          index === 1 ||
          index === 2 ||
          index === 3 ||
          index === 4 ||
          index === 5 ||
          index === 6 ||
          index === 8 ||
          index === 9 ||
          index === 13 ||
          index === 14 ||
          index === 18 ||
          index === 20 ||
          index === 21
        ) {
          sums[index] = ''
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          if (!(index === 7 || index === 8)) {
            sums[index] = sums[index].toFixed(2)
          }
        } else {
          sums[index] = ''
        }
      })
      // 总杂费
      if (!this.mdlType) {
        const MPHMF = parseFloat(sums[16]) + parseFloat(sums[17])
        sums[14] = (parseFloat(sums[12]) / parseFloat(sums[7])).toFixed(2)
        sums[16] = ''
        let a = 0
        if (MPHMF < 29.66) a = 29.66
        if (MPHMF > 29.66 && MPHMF < 575.35) a = MPHMF.toFixed(2)
        if (MPHMF > 575.35) a = 575.35
        sums[17] = a
        sums[19] = (parseFloat(sums[17]) + parseFloat(sums[15])).toFixed(2)
        sums[20] = +(+parseFloat(sums[19]) / +parseFloat(sums[12])).toFixed(2)
      } else {
        const MPHMF = parseFloat(sums[16])
        sums[14] = (parseFloat(sums[12]) / +parseFloat(sums[7])).toFixed(2)
        sums[16] = ''
        let a = 0
        if (MPHMF < 29.66) a = 29.66
        if (MPHMF > 29.66 && MPHMF < 575.35) a = MPHMF.toFixed(2)
        if (MPHMF > 575.35) a = 575.35
        sums[16] = a
        sums[17] = ''
        sums[18] = (parseFloat(sums[15]) + parseFloat(sums[16])).toFixed(2)
        sums[19] = (parseFloat(sums[18]) / this.row.weightKgs).toFixed(2)
      }
      this.sums = sums
      return sums
    },
    dblclick(row, column) {
      if (this.headFixed.indexOf(column.property) > -1) {
        // 不允许更改
        return
      }
      this.rowdata = row[column.property]
      const rowIndex = this.invoice.data.indexOf(row)
      if (column.property === 'hsCode') {
        window.open(`https://hts.usitc.gov/?query=${row.hsCode}`, '_blank')
        return
      }
      if (
        // column.property === 'packages' ||
        column.property === 'qty' ||
        column.property === 'unitPrice' ||
        column.property === 'netWeight' ||
        column.property === 'grossWeight' ||
        column.property === 'nameEN' ||
        column.property === 'nameCH' ||
        column.property === 'used' ||
        column.property === 'material'
        // column.property === 'mark'
      ) {
        this.$delete(this.invoice.data[rowIndex], 'isEdit')
        this.$set(this.invoice.data[rowIndex], 'isEdit', [column.property])
      }
    },
    cellClick: singleClick(function(row, column) {
      console.log(this.headFixed, column.property)
      if (this.headFixed.indexOf(column.property) > -1) {
        // 不允许更改
        return
      }
      this.rowdata = row[column.property]
      if (column.property === 'nameCH') {
        this.dialogVisible1 = true
        this.selectRow = row
        return
      }
      if (column.property === 'hsCode') {
        this.dialogVisible2 = true
        this.selectRow = row
        return
      }
    }),
    blur(row) {
      const ids = row.isEdit[0]
      const rowIndex = this.invoice.data.indexOf(row)
      console.log(row[ids], 'row[ids]')
      console.log(this.rowdata, 'this.rowdata')
      if (row[ids] !== this.rowdata) {
        if (
          row.updateColumnNameList &&
          row.updateColumnNameList.indexOf(row.isEdit[0]) === -1
        ) {
          row.updateColumnNameList.push(row.isEdit[0])
        }
        const newData = _.cloneDeep(this.invoice.data)
        const newRow = _.cloneDeep(row)
        newRow[ids] = this.rowdata
        newRow.isEdit = []
        newData[rowIndex] = newRow
        this.$store.commit('set_loop_item', newData)
        console.log('修改了')
      } else {
        console.log('没有修改')
      }
      row.isEdit = []
      this.$set(this.invoice.data, rowIndex, row)
    },
    handleSelectionChange(val) {
      this.mutiSelection = val
    },
    deleteRow() {
      if (this.mutiSelection.length === 0) {
        this.$message.warning('请选择条目')
        return false
      }
      this.$confirm(
        `此操作将删除${this.mutiSelection.length}条记录, 是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          iconClass: 'message-box_warning',
          showClose: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          center: true
        }
      )
        .then(() => {
          this.$store.commit('set_loop_item', this.invoice.data)
          const arr = this.invoice.data.filter((a) => {
            return this.mutiSelection.indexOf(a) < 0
          })
          this.invoice.data = arr
          this.mutiSelection = []
        })
        .catch(() => {})
    },
    merge() {
      if (this.mutiSelection.length < 2) {
        this.$message.warning('请选择1条以上的产品')
        return false
      }
      this.dialogVisible4 = true
    },
    prev() {
      if (this.$store.state.invoiceLoop?.caches.length === 0) {
        this.$message.warning('已经没有上一步了')
      }
      this.invoice.data = this.$store.state.invoiceLoop?.caches[this.$store.state.invoiceLoop?.caches.length - 1]
      this.$store.commit('reduce_loop_item')
    },
    refuse() {
      this.$prompt('请输入拒绝原因', '拒绝', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValidator: function(val) {
            if (!val) {
              return '拒绝原因不能为空'
            }
          }
        }).then(({ value }) => {
          forecastRefuse({
            mblId: this.row.id,
            refuseReason: value
          }).then(res => {
            if (res.success) {
              this.$message.success('拒绝预报成功')
              this.$store.commit('set_empty_item')
              this.$emit('cancel', 1)
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }).catch(() => {
        })
    }
  }
}
</script>
<style scoped lang="less">
.dialog-footer {
  text-align: center;
  margin-top: 10px;
}
.invoiceContent {
  position: relative;
  height: 100%;
  width: 100%;
  .label-container {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  .label-item {
    display: flex;
    align-items: center;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  .label-mark {
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #F7F7F7;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 8px;
  }
  .label-info {
    font-size: 14px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -16px;
    bottom: -48px;
    width: 102%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.16);
    border-radius: 0px 0px 6px 6px;
    .btn1 {
      width: 160px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #EEEEEE;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
      margin-right: 16px;
    }
    .btn2 {
      width: 160px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2395F3;
      border-radius: 4px 4px 4px 4px;
      cursor: pointer;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      margin-right: 16px;
    }
  }
  .tableFooter {
    position: absolute;
    left: 10px;
    display: flex;
    .selection {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 80px;
    }
    .selection_num {
      color: rgba(35, 149, 243, 1);
    }
    .btn {
      width: 80px;
      height: 30px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #cdcdcd;
      cursor: pointer;
      margin: 25px 0 25px 5px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      text-align: center;
    }
  }
}
.number {
  width: 100%;
}
.number /deep/ .el-input-number .el-input__inner {
  text-align: left;
}
.rate /deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #ccc;
}
.rate /deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #ccc;
}
.rate /deep/ .el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ccc;
}
.invoiceContent /deep/ .el-input__inner {
  padding: 0;
}
/deep/ .el-table .has-gutter tr th {
  &:first-child div {
    padding: 0;
  }
}
</style>
